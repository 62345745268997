import React, { Fragment, useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";

import classes from "./NavigationItems.css";

import List from "@material-ui/core/List";

import DashboardIcon from "@material-ui/icons/Dashboard";
import NavigationItem from "../NavigationItem/NavigationItem";
import TimerIcon from "@material-ui/icons/Timer";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { getUserInfo } from "../../../assets/shared/sessionData";

const NavigationItems = (props) => {  
  const [userInfo, setUserInfo] = useState();
  const [secciones, setSecciones] = useState([]);  
  const inPhone = window.screen.width <= 750;

  const obtenerSecciones = useCallback((userInfo) => {
    const items = [];

    if(userInfo !== null && userInfo !== undefined 
      && userInfo.perfil !== null && userInfo.perfil !== undefined
      && userInfo.perfil.perfilAcceso !== null && userInfo.perfil.perfilAcceso !== undefined) {
        const perfilesAccesos = userInfo.perfil.perfilAcceso;
        const filtrados = perfilesAccesos.filter((pa) => (pa.aplicacionKey !== undefined && pa.aplicacionKey === "vendedores") || (pa.aplicacion !== undefined && pa.aplicacion.key === "vendedores"));

        if(filtrados){
          filtrados.forEach((fil) => items.push(fil.seccion));
        }
    }
    setSecciones(items);
  }, []);

  useEffect(() => {
    if(userInfo === null || userInfo === undefined || userInfo.token === null || userInfo.token === undefined){      
      const item = props.userInfo !== null && props.userInfo !== undefined 
        && props.userInfo.token !== null && props.userInfo.token !== undefined
        ? props.userInfo
        : getUserInfo();   
      setUserInfo(item);
      obtenerSecciones(item);
    }
  }, [props.userInfo, userInfo, setUserInfo, obtenerSecciones]);

  const tieneAcceso = (key) => {
    const seccion = secciones.find((sec) => sec.key === key);
    return seccion !== null && seccion !== undefined;
  }

  return (
    <Fragment>
      <List className={classes.NavigationItems}>
        {props.isAuth && (
          <React.Fragment>
            {tieneAcceso('fotodeldia') && (
              <NavigationItem link="/fotoDelDia" title="Foto del día"
                onClickItem={props.onClickItem}>
                <DashboardIcon className={classes.NavigationItemIcon} />
              </NavigationItem>           
            )}
            {tieneAcceso('cliente') && (
              <NavigationItem link="/cliente" title="Cliente"
                onClickItem={props.onClickItem}>
                <PermContactCalendarIcon className={classes.NavigationItemIcon} />
              </NavigationItem>           
            )}
            {tieneAcceso('analisisdeventas') && (
              <NavigationItem link="/analisisventas" title="Análisis de ventas"
                onClickItem={props.onClickItem}>
                <AssessmentIcon className={classes.NavigationItemIcon} />
              </NavigationItem>           
            )}
            {tieneAcceso('catalogo') && !inPhone && (
              <NavigationItem link="/catalogo" title="Catálogo"
                onClickItem={props.onClickItem}>
                <ShoppingCartIcon className={classes.NavigationItemIcon} />
              </NavigationItem>           
            )}
            {props.gauss && (
              <NavigationItem link="/tiemposReq" title="Tiempos request"
                onClickItem={props.onClickItem}>
                <TimerIcon className={classes.NavigationItemIcon} />
              </NavigationItem>
            )}
            {props.isAdmin && (
              <NavigationItem link="/changeLogs" title="Versionado"
                onClickItem={props.onClickItem}>
                <img
                  className={classes.NavigationItemIcon}
                  style={{ marginLeft: "-3px" }}
                  alt="versionIcon"
                  src={'static/images/IconVersionBlanco.svg'}
                />
              </NavigationItem>
            )}
            {process.env.NODE_ENV === "development" && (
              <NavigationItem link="/pruebas" title="Pruebas"
                onClickItem={props.onClickItem}>
                <div className={classes.NavigationItemIcon}>PR</div>
              </NavigationItem>
            )}
          </React.Fragment>
        )}
      </List>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.token !== null,
    isAdmin: state.auth.userInfo.isAdmin,
    gauss: state.auth.userInfo.gauss,
    userInfo: state.auth.userInfo,
  };
};

export default connect(mapStateToProps)(NavigationItems);
