import React from "react";
import classes from "./Cliente.css";
import Tabs from '../../components/UI/Tabs/Tabs';
import EmpresaSelectorButton from "../../components/UI/EmpresaSelector/EmpresaSelectorButton";
import { connect } from "react-redux";
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';
import ClienteSelector from "../../components/UI/Selectors/ClienteSelector";
import { Grid } from "@material-ui/core";
import EstadoCliente from "./EstadoCliente";
import { storageMultiempresa } from "../../assets/shared/sessionData";
import NuevoPedido from "./NuevoPedido";

const Cliente = (props) => {
  sessionStorage.setItem('seccionNombre', 'Cliente');

  const { ejecucionClientes, ultimaActualizacionClientes } = props;   
  const inPhone = window.screen.width <= 750;

  const header = () => {
    return (
      <Grid 
        container
        style={{ width: '100%', marginBottom: '10px' }}
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'start' }}>
          {isMultiemp && <EmpresaSelectorButton setEmpresasessionStorage />}
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'start' }} >        
          <ClienteSelector 
            compactInput 
            compactDefault
          />
        </Grid>
      </Grid>
    );
  }

  const isMultiemp = storageMultiempresa() === 'true';

  return (
    <div className={classes.ClienteContainer}>
      {header()}
      {!ejecucionClientes ? (
        <BoxEjecutarPaginaInfo inClienteInfo />
      ) : inPhone ? (
        <EstadoCliente ejecucionClientes={ejecucionClientes} />
      ) : (
        <Tabs
          // tabs={['Estado cliente', 'Nuevo pedido']}
          tabs={['Estado cliente']}
          views={[
            <EstadoCliente ejecucionClientes={ejecucionClientes} />,
            // <NuevoPedido ejecucionClientes={ejecucionClientes} />
          ]}
          flagResetIndex={ultimaActualizacionClientes}
        />        
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ejecucionClientes: state.ejecucionPaginas.ejecucionClientes,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Cliente);