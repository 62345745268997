import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import * as actions from "../../../store/actions";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

import Spinner from "../Spinner/Spinner";
import { storageVendedorId } from "../../../assets/shared/sessionData";
import { Typography, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '3px',
  }
}));

const VendedorSelector = (props) => {
  const classes = useStyles();

  const { 
    onLoadVendedores, 
    onClearEjecuciones,
    onChangeOptionSelected,
    handleChangeVendedor,
  } = props;

  const [vendedorId, setVendedorId] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const handleTooltip = bool => {
      setTooltipOpen(bool)
  }

  useEffect(() => {
    if (props.vendedores.length === 0) onLoadVendedores();
  }, []);
  
  const setVendedor = useCallback((vendedorId) => { 
    props.closeToolbar();
    setVendedorId(vendedorId);
    const selected = props.vendedores.filter((vend) => vend.idVendedor.trim() === vendedorId.trim())[0];
    onChangeOptionSelected();
    handleChangeVendedor(selected);
  }, [props.vendedores, onChangeOptionSelected, handleChangeVendedor]);  

  useEffect(() => {
    if(vendedorId === null && props.vendedores.length !== 0){
      setVendedorId(storageVendedorId());
      setVendedor(storageVendedorId());
    }
  }, [vendedorId, setVendedor]);

  const onChangeValue = (idVendedor) => {
    sessionStorage.setItem("vendedorId", idVendedor);
    sessionStorage.setItem("clienteId", '');
    sessionStorage.setItem("listaPrecioIdClient", '');
    sessionStorage.setItem("storageListaPreciosId", '');
    sessionStorage.removeItem('observacionesObject');
    onClearEjecuciones();

    setVendedor(idVendedor);
  };

  return props.loadingVendedores ? (
    <Spinner personalSize="35px" />
  ) : (
    <div style={{ display: "flex", alignItems: "center" }}>
      <AssignmentIndIcon />
      <FormControl style={{ margin: "0px 10px 0px 10px", color: "white" }}>
        <Tooltip
          open={tooltipOpen}
          arrow
          title={
            <Typography style={{ textAlign: 'left', font: 'normal normal normal 14px/19px Roboto', color: '8D8C8C' }}>
              Seleccione un vendedor
            </Typography>
          }
          classes={{
            tooltip:classes.tooltip,
          }}
        >          
          <Select
            style={{ minWidth: 120, color: "white", fontSize: 'small' }}
            labelId="vendedorLabel"
            id="vendedorSelect"
            value={vendedorId ? `${vendedorId}` : ""}
            defaultValue={""}
            placeholder="Seleccione un vendedor"
            onChange={(event) => {
              onChangeValue(event.target.value);
            }}
            onMouseEnter={() => {handleTooltip(true)}}
            onMouseLeave={() => {handleTooltip(false)}}
            onOpen={() => {handleTooltip(false)}}
            onClose={() => {handleTooltip(false)}}
          >
            {props.vendedores.map((vendedor) => (
              <MenuItem
                key={vendedor.idVendedor}
                value={vendedor.idVendedor}
                style={{ fontSize: 'small' }}
              >
                {vendedor.nombre && vendedor.nombre.toUpperCase().trimEnd()}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </FormControl>      
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingVendedores: state.vendedores.vendedores.loading,
    vendedores: state.vendedores.vendedores.vendedores,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVendedores: (success) => dispatch(actions.loadVendedores(success)),
    onClearEjecuciones: () => {
      dispatch(actions.ejecutarFotoDelDia(false)); 
      dispatch(actions.ejecucionClientes(false));  
      dispatch(actions.ejecucionCatalogo(false));
      dispatch(actions.ejecutarAnalisisVentas(false));
      dispatch(actions.clearIndicadoresFotoDelDia());
      dispatch(actions.clearIndicadoresCliente());
      dispatch(actions.clearCatalogo());
      dispatch(actions.clearCatalogo(true));
      dispatch(actions.clearAnalisisVentas());      
      dispatch(actions.clearClientesInfoMinima());
    },
    onChangeOptionSelected: () => dispatch(actions.seleccionDataVentasDelMesDelVendedorPor('categoriaDeCliente')),
    handleChangeVendedor: (vendedor) => dispatch(actions.seleccionVendedorAnalisisVendedores(vendedor)),    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendedorSelector);