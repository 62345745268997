import React, { Suspense, useEffect, useState, useCallback } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";

import Spinner from "./components/UI/Spinner/Spinner";
import Layout from "./containers/Layout/Layout";

import * as actions from "./store/actions";

import PantallaCarga from "./containers/PantallaCarga/PantallaCarga";
import Logout from "./components/Logout/Logout";
import FotoDelDia from "./containers/FotoDelDia/FotoDelDia";
import Cliente from "./containers/Cliente/Cliente";
import Pruebas from "./containers/pruebas/Pruebas";
import SimpleBackdrop from "./components/UI/Backdrop/Backdrop";
import TiemposReq from "./containers/TiemposReq/TiemposReq";
import IframePortal from "./components/IframePortal/IframePortal";
import ChangeLogs from "./containers/ChangeLogs/ChangeLogs";

import esMessage from "devextreme/localization/messages/es.json";
import { locale, loadMessages } from "devextreme/localization";
import Catalogo from "./containers/Catalogo/Catalogo";
import AnalisisDeVentas from "./containers/AnalisisDeVentas/AnalisisDeVentas";
import { userGauss, getUserInfo } from "./assets/shared/sessionData";

const App = (props) => {
  const { onTryAuthSignin, login } = props;
  const { search } = useLocation();
  const isAdmin = sessionStorage.getItem("isAdmin") === "true";const [userInfo, setUserInfo] = useState();
  const [secciones, setSecciones] = useState([]);

  const obtenerSecciones = useCallback((userInfo) => {
    const items = [];

    if(userInfo !== null && userInfo !== undefined 
      && userInfo.perfil !== null && userInfo.perfil !== undefined
      && userInfo.perfil.perfilAcceso !== null && userInfo.perfil.perfilAcceso !== undefined) {
        const perfilesAccesos = userInfo.perfil.perfilAcceso;
        const filtrados = perfilesAccesos.filter((pa) => (pa.aplicacionKey !== undefined && pa.aplicacionKey === "vendedores") || (pa.aplicacion !== undefined && pa.aplicacion.key === "vendedores"));

        if(filtrados){
          filtrados.forEach((fil) => items.push(fil.seccion));
        }
    }
    setSecciones(items);
  }, []);

  useEffect(() => {
    if(userInfo === null || userInfo === undefined || userInfo.token === null || userInfo.token === undefined){
      const item = props.userInfo !== null && props.userInfo !== undefined 
        && props.userInfo.token !== null && props.userInfo.token !== undefined
        ? props.userInfo
        : getUserInfo();   
      setUserInfo(item);
      obtenerSecciones(item);
    }
  }, [props.userInfo, userInfo, setUserInfo, obtenerSecciones]);

  const tieneAcceso = (key) => {
    const seccion = secciones.find((sec) => sec.key !== undefined && sec.key === key);
    return seccion !== null && seccion !== undefined;
  }

  const getDefaultPath = () => {
    const seccion = secciones[0];

    if(!seccion)
      return "/fotoDelDia";

    switch (seccion.key) {
      case "fotodeldia":
        return "/fotoDelDia";

      case "cliente":
        return "/cliente";

      case "catalogo":
        return "/catalogo";

      case "analisisdeventas":
        return "/analisisventas";
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      onTryAuthSignin();
    }
  }, [onTryAuthSignin]);

  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      login(search.slice(1));
    }
  }, [login, search]);

  useEffect(() => {
    loadMessages(esMessage);
    locale(navigator.language);
  }, []);

  let routes = (
    <Switch>
      <Route path="/" component={PantallaCarga} />
    </Switch>
  );

  if (props.token !== null && !props.loadingAuth)
    routes = (
      <Switch>
        <Route path="/logout" component={Logout} />
        {tieneAcceso('fotodeldia') && <Route path="/fotoDelDia" render={(props) => <FotoDelDia {...props} />} />}
        {tieneAcceso('cliente') && <Route path="/cliente" render={(props) => <Cliente {...props} />} />}
        {tieneAcceso('catalogo') && <Route path="/catalogo" render={(props) => <Catalogo {...props} />} />}
        <Route path="/analisisventas" render={(props) => <AnalisisDeVentas {...props} />} />
        {process.env.NODE_ENV === "development" && (<Route path="/pruebas" render={(props) => <Pruebas {...props} />} />)}
        {(userGauss() || props.gauss) && <Route path="/tiemposReq" render={(props) => <TiemposReq {...props} />} />}
        {isAdmin && (<Route path="/changeLogs" render={(props) => <ChangeLogs {...props} />} /> )}
        {props.token !== null ? <Redirect push to={getDefaultPath()} /> : <Logout />}
      </Switch>
    );

  return (
    <Suspense fallback={<Spinner />}>
      <Layout>
        <SimpleBackdrop open={
          props.loadingBackdrop || props.loadingCambiarMoneda || props.loadingCambiarVendedor
        } />
        {routes}
      </Layout>
      <IframePortal />
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    loadingAuth: state.auth.loading,
    loadingBackdrop: state.empresas.loadingCambiarEmpresa,
    loadingCambiarMoneda: state.monedas.loadingCambiarMoneda,
    loadingCambiarVendedor: state.vendedores.loadingCambiarVendedor,
    userInfo: state.auth.userInfo,
    gauss: state.auth.userInfo.gauss,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAuthSignin: () => {
      dispatch(actions.authCheckState());
    },
    login: (token) => dispatch(actions.login(token)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
